#login-info {
  background-color: #f7f7f7;
  display: block;
  height: 100%;
  padding: 15px 10px;
  position: absolute; }

ul.login-logout {
  padding: 0 !important; }

.input-group {
  width: 100%; }

ul.login-logout.logged-in {
  width: unset; }

.ng-pristine.ng-valid.ng-scope {
  width: 100%;
  padding: 10px; }

.control-label {
  color: #999999;
  font-size: 10px; }

.reset-password {
  width: 100%;
  text-align: right;
  font-size: 10px; }

/*# sourceMappingURL=../../../../../../../static/hrs_pp/css/login-logout.css.map */